<script lang="ts">
	import Menu from './views/menu.svelte';
	import Landing from './views/landing.svelte';
	import Homescene from './views/homescene.svelte';
	import ViewerScene from './views/viewerscene.svelte';
	import { mode, scene1, scene2 } from './stores.js';
	import PlattarWeb from '@plattar/plattar-web';
</script>


<main>
	<Homescene/>
	<!-- <ViewerScene scene={1}/> -->
	<!-- <ViewerScene scene={2}/> -->
	<ViewerScene/>

	<Landing />
</main>

<Menu />

<style>
	main {
		position: absolute;
		height: 100%;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}

	:root {
		--pure-white: #ffffff;
		--primary-color: #b9c6d2;
		--secondary-color: #d0dde9;
		--tertiary-color: #edf0f8;
		--accent-color: #ff3e00;
		--heading-color: rgba(0, 0, 0, 0.7);
		--text-color: #444444;
		--background-without-opacity: rgba(255, 255, 255, 0.7);
		--column-width: 42rem;
		--column-margin-top: 4rem;

		--colour-blue: #ffb838;
		--colour-dark-blue: #ffb838;
		--colour-text: #d9d9d6;
		--colour-text-background: #34322e;
		--colour-white: #fff;
	}

  :global(body) {
		min-height: 100vh;
		margin: 0;
		padding: 0;
		background-color: var(--primary-color);
		background: linear-gradient(
			180deg,
			var(--primary-color) 0%,
			var(--secondary-color) 10.45%,
			var(--tertiary-color) 41.35%
		);

		position: relative;
	  width: 100%;
	  height: 100%;
  	box-sizing: border-box;
	}
</style>
