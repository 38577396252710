<script >
  import { scene, gotoCamera, contextViewer } from '../stores.js';
  import { onMount } from 'svelte';

  let viewer;
  let state;
  let sceneid;
  let scenetitle;

  onMount(async () => {
    if(viewer){
      contextViewer.set(viewer);
    }

    scene.subscribe(value => {
      if(!value.id){
        // pause scene
        if(viewer && viewer.messenger){
          viewer.messenger.pauseRenderer();
        }
        return;
      }

      viewer.context.openAnnotation = function(params){
        gtag('event', 'Open Annotation', {
          sceneid: sceneid,
          scenetitle: scenetitle,
          annotationid: params.id,
          title: params.attributes.title
        });
      }

      switch(value.state){
        case 'paused':
          viewer.messenger.pauseRenderer();
          break;

        case 'load':
          if(state == 'paused' && value.id == sceneid){
            //resume
            viewer.messenger.resumeRenderer();
            scene.set({
              id: sceneid,
              title: $scene.title,
              state: 'active'
            });
          }
          else{
            scene.set({
              id: value.id,
              title: $scene.title,
              state: 'loading'
            });
          }
          break;

        case 'loading':
          if(viewer && sceneid){
            viewer.context.setLoading = function(params){
              if(params.loading == false){
                //hide annotations
                if(sceneid !== '345ef06b-317c-4e3b-bc04-533b7f2ac2e9'){
                  viewer.messenger.hideAnnotations();
                }

                scene.set({
                  id: sceneid,
                  title: $scene.title,
                  state: 'active'
                });

                if($gotoCamera){
                  var cam = $gotoCamera;

                  gotoCamera.subscribe(value => {
                    viewer.messenger.moveToCamera(value);
                    viewer.messenger.closeAnnotation();
                  });

                  setTimeout(() => {
                    console.log('moving to camera', cam);
                    viewer.messenger.moveToCamera(cam);
                    viewer.messenger.closeAnnotation();
                  }, 1000);
                  gotoCamera.set('');
                }
              }
            };
          }
          break;
      }

      sceneid = value.id;
      scenetitle = value.title;
      state = value.state;
    });
  });
</script>

<section class='scene-viewer'>
  <section class='load-screen {$scene?.state == "loading" ? "" : "hidden"}'>
    <div class='loader-container'>
      <div class="loader"><div></div><div></div><div></div><div></div></div>

      <h1>Loading</h1>
    </div>
  </section>

  <!-- {$scene?.state != "paused" ? "back" : "front"} -->
  <section class='{$scene?.state == "active" ? "" : "hidden"}'>
    <!-- {#if sceneid } -->
    <plattar-viewer bind:this={viewer} scene-id="{sceneid}" width="100%" height='100%' />
    <!-- {/if} -->
  </section>
</section>

<style>
  section{
    transition: opacity 0.5s;

    &.hidden{
      opacity: 0;
      pointer-events: none;
    }

    /*&.back{
      plattar-viewer{
        z-index: 10;
      }
    }
    &.front{
      plattar-viewer{
        z-index: 11;
      }
    }*/
  }

  .load-screen{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    pointer-events: none;

    h1 {
      font-size: 42px;
      font-family: 'myriad-pro', 'Myriad Pro Regular', sans-serif;
      color: var(--colour-dark-blue);
      font-weight: 200;
      margin: 0;
    }

    .loader-container{
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }

  plattar-viewer{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .loader {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .loader div {
    display: inline-block;
    height: 16px;
    width: 64px;
    background: var(--colour-dark-blue);
    margin-bottom: 8px;
    animation: elevate 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }
  .loader div:nth-child(4) {
    animation-delay: -0.24s;
  }
  .loader div:nth-child(3) {
    animation-delay: -0.12s;
  }
  .loader div:nth-child(2) {
    animation-delay: 0;
  }
  .loader div:nth-child(1) {
    animation-delay: 0.12s;
  }
  @keyframes elevate {
    0% {
      background: var(--colour-blue);
    }
    75%, 100% {
      background: rgba(84, 195, 241, 0.3);
    }
  }

</style>
