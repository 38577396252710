<script>
  import { mode, scene, sceneHistory, stateHome, contextHome, contextViewer, gotoCamera, annotationsVisible } from '../stores.js';

  /* type Submenu = {
    title: string,
    sceneid?: string,
    cameraid?: string,
    annotationIds?: Array<string>
  }

  type  Menu = {
    title: string,
    submenu: Array<Submenu>
  } */

  contextHome.loadScene = function(sceneid){
    openScene(sceneid);
  };

  var menuHome = [
    {
      title: 'Anzac Class FFH',
      sceneid: '0de83f9c-db3d-4fb8-a6e0-1535249cb490',
      submenu: [],
      submenu2: ['Home', 'Combat Management System', 'Effectors', 'Sensors', 'Communication', 'Support', 'Operators']
    }, {
      title: 'Hobart Class DDG',
      sceneid: 'bb2cd7be-c0d3-46b3-867f-a97d02b16c99',
      submenu: [],
      submenu2: ['Home', 'Combat Management System', 'Effectors', 'Sensors']
    }, {
      title: 'Hunter Class FFG',
      sceneid: 'd833aba0-870a-4c72-8899-c2f21cd72d80',
      submenu: [],
      submenu2: ['Home', 'Combat Management System', 'Effectors', 'Sensors']
    }, {
      title: 'Supply Class AOR',
      sceneid: 'b4636db6-d84f-4b53-b082-a8986c9d46c9',
      submenu: [],
      submenu2: ['Home', 'Combat Management System', 'Effectors', 'Sensors']
    }, {
      title: 'Arafura Class OPV',
      sceneid: '1bcf2d6e-1255-4188-ade5-53230c26e5f1',
      submenu: [],
      submenu2: ['Home', 'Combat Management System', 'Effectors', 'Sensors', 'Support', 'Communication']
    }, {
      title: 'Canberra Class LHD',
      sceneid: '169cb26b-fa35-43b3-9652-0f062cccdede',
      submenu: [],
      submenu2: ['Home', 'Combat Management System', 'Effectors', 'Sensors', 'Communication']
    }, {
      title: 'Future Operator Workspace',
      sceneid: '345ef06b-317c-4e3b-bc04-533b7f2ac2e9',
      submenu: [],
      submenu2: ['Home', ]
    }
  ];

  var menuScene = [
    {
      title: 'Home',
      submenu: []
    },{
      title: 'Combat Management System',
      submenu: [],
      annotationIds: [
        // Anzac
        'e8359ee6-0c3f-4602-90f9-2d440c2f0442',

        // Arafura
        'b1876d40-a7f1-4b5b-a88c-2d957bc4738d',

        // Canberra
        '830d0b66-0f00-4915-bfb2-8502d60e47b7',

        // Hunter
        'f33ebe8a-99f8-43d6-9fa4-5fa69865f90c',

        // Hobart
        'cb2266e6-20cd-4a91-80d4-2bd589b9342f',
        // '215436b2-241f-4f69-a3ac-a8086db23e96',

        // Supply
        '34a9ed41-c024-4b7e-8c75-8926f95bdaa1',
      ]
    },{
      title: 'Effectors',
      submenu: [],
      annotationIds: [
        // Anzac
        '051d5eed-412d-4e41-9368-a5e532f42315',
        '3a973db0-1ef0-4200-aeee-87de74bed9ab',
        '1b5055f5-c0b4-4d2e-b0d3-5e8c5c58e22b',
        'a5bf958f-f7c1-49cc-9199-47ff56d51c05',
        '4d372ee3-762a-4af0-a51f-68076f727186',
        '5824463b-3900-4ba3-ba58-3a70963a2660',
        '2199023f-3e49-44cf-9b44-0d379dd5ad6c',

        // Arafura
        '84abd9d0-fb81-488b-b289-7f51dfcd23a7',

        // Canberra
        '316ad03a-adf9-4699-96a9-1bc85e973dd3',
        '8f33cb1f-7221-4359-9994-4224482f0ac3',

        // Hunter
        '367b898b-da30-425a-a786-15bbbaf44d00',
        'bcb48e19-210e-46d6-a79d-2594993b7aa0',
        '8da0b264-0768-4801-9f7c-ccbc8f51dd94',
        '9d65321d-444a-4dc2-855d-2fcbc6646c51',
        '61c01da6-1d51-48ff-9ff9-dc5c21a96362',
        // 'd83dd11f-17f8-434c-b880-ed9b7e47d69e',

        // Hobart
        '8175aaf8-88f4-4110-8360-98f35047627f',
        // '2e9bbbcc-0a42-40d8-b9e9-7095f276185d',
        'd7fb38fd-0e0c-4652-a402-4e13c71e4e13',
        'd476604c-c771-4590-b1a7-95a8f3af9252',
        '215436b2-241f-4f69-a3ac-a8086db23e96',

        // Supply
        '0136d3db-0c15-4920-8533-4aace3809255',
        'ea5c0e75-e798-4eab-9396-0e03285ae88e',
      ]
    },{
      title: 'Sensors',
      submenu: [],
      annotationIds: [
        // Anzac
        '6579040f-2b45-432c-a907-d4b046465ff3',
        '522f55db-b8d9-4389-b65c-972995635675',
        '578a7a92-8c9a-4fcf-8d08-62a8bf0e8a41',
        '98d6e8df-5151-4f8f-a3a9-6e1dfdfdb150',
        'bad6f718-7e4b-4600-9dc6-748bac96f2ec',
        'a4651aa4-f3e8-4427-9835-084d18071e36',
        'd289be28-e4b8-4740-a1fd-bdce9a31506d',
        '8c11d790-21a0-4cc3-ad10-6f2e63757949',

        // Arafura
        '8b00728d-081c-4d66-b89b-cd29182451ab',
        '405c4d36-f660-4877-b5d6-c228e165d1e6',
        '03266169-cd01-4147-a55a-98ca1b74831d',
        '455647ba-4339-4094-968a-ec1707904203',
        '0b08a831-148a-4aba-b7bc-daab1bf82952',
        'f51462dd-5da4-4f66-95fe-335c1c1a4c00',
        // 'efef4534-97b2-4ca6-acf0-46ad355ef146',

        // Canberra
        // 'ca29e1ae-9b44-41f4-ad0e-87d04ee152ab',
        '6889d24e-b5fc-4610-8e4c-e7e79df8a980',
        '2855fc57-0153-4bd2-8946-f5867595666a',
        '38bcfc51-12f5-4855-af54-22983a76178f',
        'e0b9bef1-50e4-4159-85e5-c4db95b6a6e6',
        'c927f1d6-d4d0-43d9-af59-dbc408a5e784',
        '673bd041-6064-4d4d-a557-1668be0b2f29',
        'd35ed6a7-7860-42f3-84fc-9156c9f4d285',
        '8761ad75-8d3d-4b42-9af1-4ab560ec46a6',
        'e7c1f181-8316-4ec5-9163-bbcb873f6507',

        // Hunter
        '98529796-b521-4124-a8e8-76530e487e94',
        // 'a1fa85ab-8ca5-47a4-ba5d-fdf1e572ba66',
        '61c0eecb-a16d-4691-a07c-2805777e594b',
        'e9c2f397-22e0-4bd6-9791-215e45a16fa2',
        '4cda1d21-c0f3-46dc-a01d-e77a22c000e0',
        '8f7d389a-a3db-4996-b3fd-b7f093e44bfc',
        '7b5c25ff-5a5a-4e91-8dc2-546d167a42a6',
        '78775716-671b-4c49-a8ed-6792995b5343',
        'c8999fb0-283c-47f5-9080-64d0d4319215',
        '3b36f9f4-b3ba-4d6a-8bc3-ea839161e8d4',
        '91cbb99a-2ea2-42dd-93b1-c5e732884f84',
        'cbc66b1d-5578-4e2d-8f5d-614b78055f61',
        '36565f24-2cba-435d-8aac-65a71389ad76',

        // Hobart
        '5b631f9c-b9ff-47e4-b89e-fe717fbc893c',
        '424dc116-3303-4346-b295-0e67ada78b20',
        'b511643e-cc45-467f-8ac0-3c4834217292',
        'cd9d1451-a657-428f-867a-66c13a1b6300',
        '4dd51a47-3a73-44d6-8339-ef0f1fc64ad7',
        // '4e225d44-0b98-4b7e-866d-9b431461044a',
        'dadbc0c2-cb0a-45cb-9b14-d450ec0c336a',
        // '4581fa21-63a2-4e03-88bb-eb122cf0a277',
        // '18c74d8b-1afc-4652-ab1d-61b6edada037',

        // Supply
        '424ee3cc-360b-4c88-b0c4-3e8e684acb18',
        'e91f608b-6cf8-4cdd-81a0-f01f025ffadb',
        'abcb07dc-5a0a-46f0-9359-3adc8bf40a59',
        '1455346e-4ff7-4b2d-8a96-4d884cf3e597',
        // '515addd7-ae4c-496b-a856-a91db37792b0',
        // '5ee177ee-b87c-4813-9c2a-6403395e397e',
        // 'b880ee93-8acd-4414-bd47-d71968a03acc',
        // '34a9ed41-c024-4b7e-8c75-8926f95bdaa1',
        // '7b932ee7-3c0d-4561-9c53-79adeb421c66',
        '39b3fa7b-7af1-47f6-8b6f-15c4062d9146',
        'baed4c1a-dfb5-48aa-b552-46e54c50218c',

      ]
    },{
      title: 'Communication',
      submenu: [],
      annotationIds: [
        // Anzac
        '36b4f553-e522-45ac-ab6f-e935770c45c4',
        '2c59fcf4-e5d9-43d9-9d29-d94b755c8954',

        // Arafura
        '1b7dc187-ca3b-4684-983b-1f06ca73e49f',
        'efef4534-97b2-4ca6-acf0-46ad355ef146',

        // Canberra
        '11d7d985-b15d-4156-8576-afbfeb20c8cc',
        'c982b5e7-cbd5-4f8d-9be9-72595fe6feb0',

        // Hunter
        // '11acebac-2d6b-45e5-b219-6c31e02f4af0',
        // '15f45cf4-462a-4dbd-a937-a1b9de992aa1',

        // Supply
        // '39b3fa7b-7af1-47f6-8b6f-15c4062d9146',
        // '9de17ab6-6b7b-4a22-bc93-558a8eeff0f4',
      ]
    },{
      title: 'Support',
      submenu: [],
      annotationIds: [
        // Anzac
        'e8359ee6-0c3f-4602-90f9-2d440c2f0442',

        // Arafura
        'a5a780b1-8060-4501-80ad-dfc54f9797d7',

        // Canberra
        // '11d7d985-b15d-4156-8576-afbfeb20c8cc',

        // Hunter
        // 'b6564aec-2808-4e4b-a155-dd903a43e29a',

        // Supply
        // '588f2850-2fcd-4fa7-a982-95e4a188101a',
      ]
    },{
      title: 'Operators',
      submenu: [],
      annotationIds: [
        // Anzac
        'b0e5bdd8-e84d-4b14-b049-e2e24fb6c786',
        '1fac8241-17a8-404d-b0a5-4cbdd74fc9ac',
        '57862ccd-05a6-4839-9947-e7b061c2c400',
        'b17e365d-2910-4a41-ac51-a362f3ee7aca',
        'fbd56a42-22a7-41fb-a669-4ab6308fc77c',
        'bf2ed548-fab6-4ace-a5b5-a42d21c6e1ce',

        // Arafura
        // 'b6fcdb79-36c6-4eaa-b4ba-496f9255c71b',
        // '6268183a-e60c-4222-ad68-3fce7b88f8be',
        // '3a2cff15-fe39-47fa-b104-c968e5d720b2',
        // '3510a1f6-e3aa-4dcf-b351-e1acda64da45',
        // '3f926931-ddcb-4037-b4f8-6d1f03fa8216',
        // '82cb4eff-c3db-426f-b712-963560e82b97',
        // '5934a947-6a5d-4685-9299-7a0d341dd39e',
        // '84c5b317-7f7c-4f61-80c1-899b49693615',
        // '7ccfec9e-bd5a-4ea4-a631-56a91c0089b2',
        // '2449844f-7094-4c85-b5ce-99c0bd600a4f',
        // '2cd85422-b9f2-4a8e-bb67-43ad6fba621e',
        // 'bb099f44-8e9e-4edb-8640-afebc4e68d4d',
        // '0bb6a39c-5b0f-44f6-9142-5ac85ca031a3',
        // '713d00cf-7a89-4df5-926e-42c150cd9dc4',
        // '10a8bcfe-e726-4b96-80b0-d73281dedd01',

        // Canberra
        // '6b49fa8f-0f91-4a97-a021-25655f9362fe',
        // '578d534f-fc0a-4b1b-b52d-0c45cb7b968d',
        // 'bb8dde05-d854-4b89-9f62-c3cec94f4759',
        // 'edd41f48-9d5f-491b-81ff-235516c67e10',
        // 'b1c92076-4112-435c-a0dc-8fb3076a8261',
        // '9c5db355-0071-46e5-8e05-587ee6fd40f1',
        // 'c982b5e7-cbd5-4f8d-9be9-72595fe6feb0',
        // '23ddedf0-19f3-4857-b4eb-289112fa49d7',
        // 'e00daa45-9a45-4b07-ae89-9ea981a371ce',
        // 'c8941405-9bee-4071-a493-c63140f81013',
        // '72ba7256-2991-4fd3-ab5c-bce6d69ee59e',
        // 'e83b7405-ce8c-41c9-9609-8d66b34af562',

        // Hunter
        // '3a8ecc28-c8ce-47b8-a8aa-29169ad71774',
        // 'ccef8907-6994-43c1-84c4-3b6f02e84b1d',
        // 'e82aeb81-4508-49f2-9579-75f6584f2214',
        // 'e924ead0-b9dd-4fbc-8db5-aed65e5debcb',
        // 'b6564aec-2808-4e4b-a155-dd903a43e29a',
        // '82609616-af03-414d-8a7a-cce4f5fb25b5',
        // 'fc1abd78-5a72-486d-b679-0a2640eb8d49',
        // 'b6795bb3-08ae-eb9b-ac98-3596f7a90ea9',
        // 'b58d276f-deb7-98cd-be25-78679b2ade80',
        // '79a3e800-5f84-406c-674f-018fda2e46c1',
        // 'a687c04c-2dbe-d28a-5f0a-9326909c8cf0',
        // 'e9e84cf0-9e98-15c3-e677-b80304d63a2e',

        // Hobart
        // 'db82c156-bafe-474e-9057-14e1015dd11f',
        // '45e14dab-54e8-4910-9822-13cfff075e1d',
        // '48dc623a-8aef-4e52-853d-897c16d88bdc',
        // 'a22da278-d4b2-49a2-9915-db9e4b1461c5',
        // '308fbbad-7b4a-4650-aa97-b7a4fbfdfc89',
        // 'fdeb3e56-390e-4ab0-9b00-3954466d5e67',
        // 'e123fd86-e151-45f6-8313-ae1627a15147',
        // 'cfd18bb3-38bd-49d0-89b5-c48ec96f27ee',
        // '524cfd87-c474-4183-8eb0-208e60737100',
        // 'b7c69cde-59a7-4fec-a512-4dd7000858d9',
        // '60b80dde-5250-4da3-91d7-cb4ebd4d7c14',
        // 'ff48905f-39fd-4865-92a7-9a085996c1fd',

        // Supply
        // 'fe457c86-d4ae-4b10-a560-8ff5f83fc8c4',
        // '82ab6599-25be-4fed-ac9f-5f2caf0c2b38',
        // 'bd72a4f8-f671-4d71-9b16-57e19487a596',
        // '83455316-1dfa-4dbe-ab81-84df80fe2c40',
        // 'ea7d4cb7-cdae-4f7d-b769-439578fa5230',
        // '081c92b9-b1af-4aec-befe-7c9880e8be5c',
        // 'd3f18756-35fb-4b04-bae2-2300faba1ce9',
        // '96ab5388-be9a-409f-ba13-3dc2a15e2f8d',
        // 'ffd09a38-5cbc-48d7-8a80-3911f8b86af0',
        // 'ccfddbd4-a823-4c54-850b-34a91e7f7442',
        // '65a9b3ff-c88f-44e9-ab67-63c674ebe58a',
        // '7592b865-e955-4ff1-b394-d48e48aede4f',
      ]
    },
  ];

  let menu;

  function handleClick(item){
    if(item.sceneid){
      openScene(item.sceneid);
    }
    if(item.cameraid){
      openCamera(item.cameraid);
    }
    if(item.annotationIds){
      showAnnotations(item.annotationIds);
    }
    if(item.annotationid){
      showAnnotations([item.annotationid]);
    }

    // if none of the above, go back
    if(!item.sceneid && !item.cameraid && !item.annotationIds && !item.annotationid){
      back();
    }
  }

  function openScene(sceneid){
    scene.set({
      id: sceneid,
      title: 'Loading...',
      state: 'load'
    });

    let option = menuHome.find(item => {
      return item.sceneid == sceneid
    });

    menu = menuScene.filter(item => {
      return option.submenu2.includes(item.title);
    });
  }

  function back(){
    scene.set({
      id: '',
      state: 'unloaded'
    });

    menu = menuHome;
  }


  stateHome.subscribe((value) => {
    if(value == 'active' && !menu){
      menu = menuHome;
    }
  });

  var hintTimer;
  function showHints(){
    // gtag('event', 'UI Clicked', {button: 'Help'});
    document.getElementById('hints').classList.remove('hidden');
    if(hintTimer){
      clearTimeout(hintTimer);
    }
    hintTimer = setTimeout(function(){
      document.getElementById('hints').classList.add('hidden');
      hintTimer = undefined;
    }, 3000);
  }

  function openCamera(cameraid, annotationid){
    if(cameraid){
      $contextViewer.messenger.moveToCamera(cameraid);
      $contextViewer.messenger.closeAnnotation();

      $contextViewer.messenger.disableAutorotate();
    }

    if(annotationid){
      $contextViewer.messenger.hideAnnotations();
      $contextViewer.messenger.showAnnotations([annotationid]);
      $contextViewer.messenger.openAnnotation(annotationid);
    }
  }

  function showAnnotations(annotationIds){
    $contextViewer.messenger.closeAnnotation();
    $contextViewer.messenger.hideAnnotations();
    if(annotationIds){
      $contextViewer.messenger.showAnnotations(annotationIds);
    }
  }
</script>

{#if menu}
<div class='background'></div>

{#if $sceneHistory.length}
<div class='back'>
  <button class='round-button' on:click={back}>
    <img src="./assets/prev.svg" alt='back'/>
  </button>
</div>
{/if}

{#if $stateHome == 'paused'}
<div class='home'>
  <button class='round-button' on:click={home}>
    <img src="./assets/home.svg" alt='home'/>
  </button>
</div>
{/if}

<div id='hints' class='help-container hidden'>
  <div class='help'>
    <h2>How to Use</h2>

    <div class='instructions desktop'>
      <div>
        <img src="./assets/mouse-left.png" alt='Left Mouse'>
        <p>Click & hold left mouse button to rotate view</p>
      </div>
      <div class='bar'></div>
      <div>
        <img src="./assets/mouse-scroll.png" alt='Scroll Wheel'>
        <p>Use the scroll wheel to pull/push</p>
      </div>
    </div>

    <div class='instructions mobile'>
      <div>
        <p>Use one finger to rotate</p>
      </div>
      <div class='bar'></div>
      <div>
        <p>Use two fingers to pull/push</p>
      </div>
    </div>
  </div>
</div>


<div class='menu-bar'>
  <ul class='menu'>
    <li class='menu-item' style='flex-grow: 0.25;' on:click={showHints}>?</li>
    {#each menu as item}
      <!-- if there is no sub menu -->
      {#if item.submenu.length <= 1}
        <li class='menu-item' on:click={() => {
          handleClick(item);
        }}>
          <a>
            {item.title}
          </a>
        </li>
      {/if}

      <!-- if there is a sub menu -->
      {#if item.submenu.length > 1}
        <li class='menu-item'>
          {item.title}

          <ul class='submenu'>
            {#each item.submenu as subitem}
              <li class='submenu-item' on:click={() => {
                if(subitem.cameraid){
                  openCamera(subitem.cameraid, subitem.annotationid)
                }
                if(subitem.annotationIds){
                  showAnnotations(subitem.annotationIds);
                }
                }}>
                <div class='inner'>
                  {subitem.title}
                </div>
              </li>
            {/each}
          </ul>
        </li>
      {/if}
    {/each}
  </ul>
</div>
{/if}

<style type='less'>

  .help-container{
    position: absolute;
    bottom: 200px;
    width: 100%;
    pointer-events: none;
    font-family: 'myriad-pro', 'Myriad Pro Regular', sans-serif;
    transition: opacity 0.5s;

    &.hidden{
      opacity: 0;
    }

    .help{
      background-color: var(--colour-text-background);
      color: var(--colour-text);
      margin: auto;
      max-width: 500px;
      padding: 20px;
      text-align: center;

      h2{
        margin: 0;
        margin-bottom: 20px;
      }

      .instructions{
        display: grid;
        grid-template-columns: 1fr 1px 1fr;
        grid-gap: 20px;

        .bar{
          background-color: var(--colour-text);
          width: 1px;
          height: 100%;
        }

        p{
          margin: 0;
        }

        img{
          max-height: 100px;
          filter: invert(1);
        }

        &.mobile{
          display: none;
        }

        @media (max-width: 768px){
          &.mobile{
            display: grid;
          }
          &.desktop{
            display: none;
          }
        }
      }
    }
  }

  .background{
    height: 100vh;
    background-image: url('../assets/background2.png');
    background-position: center;
    background-size: cover;
  }

  .back{
    position: fixed;
    top: 20px;
    left: 20px;
  }

  .home{
    position: fixed;
    top: 20px;
    right: 20px;
  }

  .round-button{
    background-color: white;
    width: 75px;
    height:  75px;
    border-radius: 100px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s;
    cursor: pointer;
  }
  .round-button img{
    min-width: 30px;
  }
  .round-button:hover{
    background-color: var(--colour-blue);
    img{
      filter: saturate(0) brightness(10);
    }
  }

  .menu-bar {
    position: fixed;
    bottom: 20px;
    height: 72px;
    width:  100%;
  }

  .menu-bar .menu{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    margin: 0;
  }

  .menu-bar .menu .menu-item{
    flex-grow: 1;
    list-style: none;
    font-family: 'myriad-pro', 'Myriad Pro Regular', sans-serif;
    text-transform: uppercase;
    cursor: pointer;

    background-color: var(--colour-text-background);
    text-align: center;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s, color 0.3s;
    color: var(--colour-text);
    letter-spacing: 1px;
  }
  .menu-bar .menu .menu-item:hover{
    background-color: var(--colour-blue);
    color: var(--colour-white);
  }

  .submenu{
    position: absolute;
    bottom: 72px;
    padding-left: 0px;
    padding-bottom: 20px;

    background-image: url('../assets/tick.svg');
    background-position: bottom left;
    background-repeat: no-repeat;
    background-size: 20px 20px;

    max-height: 0;
    overflow: hidden;
    padding-bottom: 0;
    transition: max-height 0.4s, padding-bottom 0.4s;
  }
  .menu-bar .menu .menu-item:hover .submenu{
    max-height: 400px;
    padding-bottom: 20px;
  }

  .submenu .submenu-item{
    padding: 0px 15px;
    list-style: none;
    transition: background-color 0.3s, color 0.3s;
    background-color: var(--colour-text-background);
    color: var(--colour-text);
  }
  .submenu .submenu-item .inner{
    padding: 8px 0px;
    min-width: 125px;
    border-bottom: solid 1px #d8d8d8;
  }
  .submenu .submenu-item:last-child .inner{
    border-bottom: none;
  }
  .submenu .submenu-item:hover{
    background-color: var(--colour-blue);
    color: var(--colour-white);
  }
</style>