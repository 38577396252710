<script >
  import { mode, stateHome, contextHome, gotoCamera, annotationsVisible } from '../stores.js';
  let loaded = false;

  // gtag('event', 'landed', {});

  /* gotoCamera.subscribe(value => {
    if(value){
      $contextHome.messenger.moveToCamera(value);
      $contextHome.messenger.closeAnnotation();

      $contextHome.messenger.disableAutorotate();
    }
  });

  annotationsVisible.subscribe(value => {
    if(value && value.length){
      $contextHome.messenger.hideAnnotations();

      $contextHome.messenger.showAnnotations(value);
    }
  }); */

  stateHome.subscribe(value => {
    if(value == 'loaded'){
      loaded = true;
      $contextHome.messenger.hideAnnotations();
    }
  });

  function start(){
    if(loaded){
      mode.set('scene');
      stateHome.set('active');
      /* gtag('event', 'Open Scene', {
        sceneid: '48258020-d025-11ec-a70b-e7b2071f94ef',
        scenetitle: 'Lobby'
      }); */
    }
  }
</script>

<section class='{$mode == "landing" ? "" : "hidden"}'>
  <div class='content-container'>
    <div class='content'>

      <div>
        <picture>
          <img src="./assets/logo.svg" alt="logo" />
        </picture>
        <h1>AUSCMS<br/>SHOWCASE</h1>
        <p>
          <!-- Explore the different air solution possibilities to fit your needs. -->
        </p>
      </div>

      <button class='loading {!loaded ? "disabled" : ""}' on:click={start}>
        {#if !loaded}
          <span class='loader'></span>
          Loading...
        {:else}
          Start <img src="./assets/next.svg" alt="Play" />
        {/if}
      </button>
    </div>
  </div>
</section>


<style type='less'>
  section{
    background: white;
    height: 100%;
    background-image: url('../assets/background.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: opacity 0.5s;

    &.hidden{
      opacity: 0;
      pointer-events: none;
    }
  }

  .content-container{
    background-image: url('../assets/slice.svg');
    background-repeat: no-repeat;
    height: 100%;
    background-size: auto 100%;
    background-position: left;
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: baseline;
    width: 50%;
    width: 50%;
    max-width: 400px;
    min-width: 300px;
    padding: 40px;
    height: calc(100% - 80px);
  }

  h1 {
    font-size: 55px;
    font-weight: 400;
    font-family: Roboto, Arial, sans-serif;
    color: var(--colour-text);
    font-weight: 200;
  }

  p{
    font-size: 16px;
    font-family: Roboto, Arial, sans-serif;
    color: var(--colour-text);
  }

  button{
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    font-family: Roboto, Arial, sans-serif;
    margin-bottom: 100px;
    border: none;
    width: 175px;
    height: 175px;
    border-radius: 100px;
    letter-spacing: 2px;
    transition: color 0.5s, background-color 0.5s;

    background-color: var(--colour-blue);
    color: var(--colour-text-background);
    cursor: pointer;
    position: relative;

    .loader{
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: var(--colour-dark-blue);
      border-radius: 100px;
      animation: pulseanim 1s linear infinite;
    }
  }

  button.disabled{
    background-color: rgba(255, 184, 56, 0.3);
    color: var(--colour-text-background);
  }

  button img{
    margin-left: 10px;
  }

  @keyframes pulseanim {
    0% { transform: scale(0); opacity: 0.8;}
    100% { transform: scale(1); opacity: 0;}
  }
</style>
