<script >
  import { stateHome, contextHome } from '../stores.js';
  import { onMount } from 'svelte';

  let viewer;
  const homesceneid = 'd9a236be-b18a-437f-98fc-433ca5535d17';

  onMount(async () => {
    if(viewer){
      viewer.context.setLoading = function(params){
        if(params.loading == false){
          stateHome.set('loaded');
          viewer.messenger.resumeRenderer();
          setTimeout(()=>{
            viewer.messenger.pauseRenderer();
          },100);
        }
      };
      contextHome.set(viewer);
    }
  });

  stateHome.subscribe((value) => {
    if(viewer){
      if(value == 'active'){
        viewer.messenger.resumeRenderer();
      }
      else{
        viewer.messenger.pauseRenderer();
      }
    }
  });

</script>

<section>
  <plattar-viewer bind:this={viewer} scene-id="{homesceneid}" width="100%" height='100%' />

  <div class='background {$stateHome == "paused" ? "" : "hidden"}'></div>
</section>


<style type='less'>
  plattar-viewer{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .background{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: white;
    pointer-events: none;
    opacity: 1;
    transition: opacity 0.5s;

    &.hidden{
      opacity: 0;
    }
  }
</style>
