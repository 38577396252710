import { writable } from 'svelte/store';

export const mode = writable('landing');
export const stateHome = writable('loading');
export const contextHome = writable(null);
export const contextViewer = writable(null);

export const scene = writable({id:"", state:"unloaded"});
export const scene1 = writable({id:"", state:"unloaded"});
export const scene2 = writable({id:"", state:"unloaded"});
export const sceneHistory = writable([]);
export const gotoCamera = writable('');
export const openAnnotation = writable('');
export const annotationsVisible = writable([]);
